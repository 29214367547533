<template>
  <div style="padding: 10px">
    <div style="margin: 10px 0">
      <el-input v-model="search.levelName" placeholder="请输入咸将塔层数，如450" @keyup.enter.native="load" style="width: 20%"
                clearable></el-input>
      <el-input v-model="search.monstersLv" placeholder="请输入敌方等级，如35720" @keyup.enter.native="load" style="width: 20%"
                clearable></el-input>
      <el-input v-model="search.monsterOneName" placeholder="请输入敌方咸将名称，如孙坚" @keyup.enter.native="load" style="width: 20%"
                clearable></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
      <el-button style="margin-left: 5px" @click="resetData">重置</el-button>
      <el-button @click="$router.push('/login')" v-if="this.userId == undefined">返回登录</el-button>
    </div>
    <!--搜索区域-->
    <el-table
            v-loading="loading"
            :data="tableData"
            border
            stripe
            style="width: 100%">
      <el-table-column
              prop="levelName"
              label="层数">
      </el-table-column>
      <el-table-column
              prop="monstersLv"
              label="敌方等级">
      </el-table-column>
      <el-table-column
              prop="zhanLi"
              :formatter="zhanLiFormatter"
              label="敌方战力">
      </el-table-column>
      <el-table-column
              prop="monsterOneName"
              label="1号位敌人">
      </el-table-column>
      <el-table-column
              prop="monsterTwoName"
              label="2号位敌人">
      </el-table-column>
      <el-table-column
              prop="monsterThreeName"
              label="3号位敌人">
      </el-table-column>
      <el-table-column
              prop="monsterFourName"
              label="4号位敌人">
      </el-table-column>
      <el-table-column
              prop="monsterFiveName"
              label="5号位敌人">
      </el-table-column>
    </el-table>

    <div style="margin: 10px 0">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>

  import request from "@/utils/request";

  export default {
    name: 'towerFormation',
    components: {},
    data() {
      return {
        dialogVisible: false,
        form:{
          createTime:'',
        },
        userId: '',
        loading: true,
        search: {},
        query: {},
        currentPage: 1,
        pageSize: 10,
        total: 0,
        tableData: [],
      }
    },
    created() {
      this.load()
      let userStr = sessionStorage.getItem("user") || "{}"
      let user = JSON.parse(userStr)
      this.userId = user.id;
    },
    methods: {
      exportUser() {
        location.href = "http://182.92.223.123:9090/saltedFishSource/export";
      },
      resetData() {
        this.search = {}
        this.load()
      },
      load() {
        this.loading = true
        const searchForm = {...this.search}
        let obj = Object.assign(searchForm, this.query)
        let reqData = {
          ...obj
        }
        reqData.current = this.currentPage
        reqData.size = this.pageSize
        request.get("/towerConf/list", {
          params: reqData
        }).then(res => {
          this.loading = false
          this.tableData = res.data.records
          this.total = res.data.total
        })
      },
      handleSizeChange(pageSize) {   // 改变当前每页的个数触发
        this.pageSize = pageSize
        this.load()
      },
      handleCurrentChange(pageNum) {  // 改变当前页码触发
        this.currentPage = pageNum
        this.load()
      },
      zhanLiFormatter(row){
        const zl = row.zhanLi;
        if(zl > 10000 && zl <= 100000000){
          return (zl/10000).toFixed(2) + '万';
        }
        if(zl > 100000000 && zl <= 1000000000000){
          return (zl/100000000).toFixed(2) + '亿';
        }
        if(zl > 1000000000000){
          return (zl/1000000000000).toFixed(2) + '兆';
        }
      }
    }
  }
</script>
